import React from "react"
import { Link } from "gatsby"
import Container from '../components/container'

import * as styles from './stores-list.module.css'

const StoresList = ({stores, storeBasePathName, slug}) => {

    return (
        <Container className={styles.storesListContainer}>
            <ul
                style={{
                    listStyleType: "none",
                    padding: 0,
                    marginTop: 0,
                    marginBottom: '10px',
                    maxWidth: '200px',
                    color: '#fff'
                }}
            >
                {stores !== null && stores.map((store, index) => {
                    const name = store.name
                    return (
                        <li
                            key={index}
                            style={{
                                marginBottom: 5,
                            }}
                        >
                            <Link to={`${storeBasePathName}/${store.slug}`} style={slug === store.slug ? {textDecoration: 'underline'}: {}}>{name}</Link>
                        </li>
                    )
                })}
            </ul>
        </Container>
    )
}

export default StoresList
