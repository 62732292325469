import React, {useState} from "react"
import { navigate } from "gatsby"
import Container from '../components/container'

const RegionsList = ({storeBasePathName, regions = []}) => {

    const [selectedOption, setSelectedOption] = useState(storeBasePathName);

    const handleChange = (value) => {
        setSelectedOption(value)
        navigate(value)
    }

    return (
        <Container
            className="select-container"
        >
            <select
                value={selectedOption}
                onChange={e => handleChange(e.target.value)}
                className="select green"
            >
                <option key='nous-trouver' value="/nous-trouver">Sélectionner une région</option>
                {regions.map(region => <option key={region.slug} value={`/nous-trouver/${region.slug}`}>{region.name}</option>)}
            </select>
        </Container>
    )
}

export default RegionsList
