import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../components/seo'
import StoresList from '../components/storesList'
import Container from '../components/container'
import RegionsList from '../components/regionsList'
import * as styles from './store-post.module.css'

class LocationPostTemplate extends React.Component {
  render() {

    const regionStoresList = this.props.pageContext?.regionStoresList
    const storeBasePathName = this.props.pageContext?.storeBasePathName
    const storeData = this.props.data.contentfulStore
    const regions = this.props.data.allContentfulRegion.nodes

    return (
      <Container className={styles.storeConainer}>
        <Seo title={storeData.name} description={`Découvrer nos produits chez ${storeData.name}`} />
        <RegionsList storeBasePathName={storeBasePathName} regions={regions} />
        <div className={styles.storeContent}>
          <StoresList stores={regionStoresList} storeBasePathName={storeBasePathName} slug={this.props.pageContext.slug} />
          <Container className={styles.storePostConainer}>
            <h2>{storeData.name}</h2>
            <p
              style={{maxWidth: '300px'}}
              dangerouslySetInnerHTML={{
                __html: storeData?.address?.address.replace(/(?:\r\n|\r|\n)/g, '<br />'),
              }}
            />
            <p style={{maxWidth: '250px'}}>
              <a target="_blank" rel="noreferrer noopener" href={`https://www.google.com/maps/place/${storeData?.address?.address.replace(/(?:\r\n|\r|\n|,)/g, '+')}`}>Google map →</a>
            </p>
          </Container>
        </div>
      </Container>
    )
  }
}

export default LocationPostTemplate

export const pageQuery = graphql`
  query StoreIndexQuery(
    $slug: String!
  ) {
    contentfulStore(slug: { eq: $slug }) {
      slug
      name
      address {
        address
      }
    }
    allContentfulRegion {
      nodes {
        name
        slug
        stores {
          slug
          name
          longitudeLatitude {
            lat
            lon
          }
        }
      }
    }
  }
`
